// This is a copy paste from aurora to account for non generic components that need to do
// email validation.

// Explanation of the email regex pattern:
// [a-zA-Z0-9\\.\\_\\%\\+\\-\\u0080-\\uFFFF]+: This part matches the username of the email address. It allows one or more occurrences of the following characters:
// Alphanumeric characters (a-z, A-Z, 0-9)
// Underscores (_)
// Percentage signs (%)
// Plus signs (+)
// Hyphens (-)
// Any Unicode character in the range \u0080-\uFFFF (which includes non-ASCII characters)
const usernameCaptureGroup = '[a-zA-Z0-9\\_\\%\\+\\-\\u0080-\\uFFFF]';

// The following ensures that:
// dots (.) cannot appear multiple times in a row
// dots (.) cannot appear at the end of the group
// (?:\.[usernameCaptureGroup]+)* ensures that if there's a period at the end of a string,
// it must be followed by one or more valid characters.
const username = `(${usernameCaptureGroup}+(?:\\.${usernameCaptureGroup}+)*)`;

// ([a-zA-Z0-9\\-\\u0080-\\uFFFF]+\\.)+: This part matches the domain name. It allows one or more occurrences of the following:
// Alphanumeric characters (a-z, A-Z, 0-9)
// Hyphens (-)
// Any Unicode character in the range \u0080-\uFFFF (which includes non-ASCII characters)
// Followed by a literal dot (.)
// The + outside the parentheses means that this entire group of domain name elements can repeat one or more times. This allows for subdomains like example.subdomain.
// Note that the first character cannot be a - so we setup the regex accordingly.
const domain = '([a-zA-Z0-9\\u0080-\\uFFFF][a-zA-Z0-9\\-\\u0080-\\uFFFF]*\\.)+';

// [a-zA-Z\\u0080-\\uFFFF]{2,}: This matches the top-level domain (TLD) of the email address. It allows two or more occurrences of either:
// ASCII uppercase or lowercase letters (a-z, A-Z)
// Any Unicode character in the range \u0080-\uFFFF (which includes non-ASCII characters)
// This ensures that the TLD can be two or more letters in ASCII or any script/language using Unicode characters.
const extension = '[a-zA-Z\\u0080-\\uFFFF]{2,}';

export const BASIC_EMAIL_REGEX = `^${username}@${domain}${extension}$`;
